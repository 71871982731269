import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';
import { PrimaryLinkButtonWithoutGatsby } from '../button';
import { ContentDivisor } from '../divisor';
import { InstagemIcon, WhatsappIcon } from '../icons';
import { H2 } from '../titles';
import FadedCircle from '../../images/Circulo-Dourado-Desfocado.png';
import { openWP } from '../modal/whatsappModal';

const ClinicalBodyContainer = styledComponents.div`
  background-color: rgb(243,243,243);
  width: 100%;
  background-image: url(${FadedCircle});
  background-size: 650px 650px;
  background-repeat: no-repeat;
  background-position-y: calc(100% + 292px);
  background-position-x: calc(50% - 517px);
`;

const ContentContainer = styledComponents.div`
display: flex;
justify-content: flex-end;
align-items: center;
flex-wrap: no-wrap;
margin: 0 auto;
max-width: 1200px;
text-align: right;
margin-top: -118px;

p {
  font: normal normal normal 16px/26px PT Sans;
  letter-spacing: 0.48px;
  line-height: 0.7;
  color: ${THEME.TEXT.BODY.COLOR};
  display: flex;
  justify-content: flex-end;

  strong {
    font: normal normal bold 22px/26px PT Sans;
    letter-spacing: 0.66px;
    color: #D0AD67;
    margin-left: 20px;
  }
}

.gatsby-image-wrapper {
  max-width: 440px;
}

& > div {
  padding: 2px
}

& > div > div {
  margin-bottom: 36px;
}

@media screen and (max-width: 1223px){
  margin-top: -10px;
}

@media screen and (max-width: 740px){
  flex-wrap: wrap;

  & > div {
    padding: 10px 36px;
  }
}
`;

const ClinicalBody = () => (
<ClinicalBodyContainer>
  <ContentDivisor />
  <H2>Corpo Clínico</H2>
  <ContentContainer>
    <div>
      <div>
        <p><a href='https://www.instagram.com/drrenatoangeluccidermato/' target='_blank' rel="noreferrer"><InstagemIcon /></a><strong>Dr. Renato Angelucci</strong></p>
        <p>Dermatologista | CRM 120766</p>
      </div>
      <div>
        <p><strong>Dra. Ana Carolina D. P. Angelucci</strong></p>
        <p>Dermatologista | CRM 135428</p>
      </div>
      <PrimaryLinkButtonWithoutGatsby onClick={openWP}>
        <WhatsappIcon color={ THEME.COLORS.SECONDARY } />Marque uma consulta
      </PrimaryLinkButtonWithoutGatsby>
    </div>
    <StaticImage src='../../images/Ana-Renato@2x.png' quality={100}/>
  </ContentContainer>
</ClinicalBodyContainer>
);

export default ClinicalBody;
