/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { StaticImage } from 'gatsby-plugin-image';

class HomeCarousel extends Component {
  render() {
    return (
            <Carousel
                interval={5000}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                autoPlay>

                {this.props.children}

            </Carousel>
    );
  }
}

const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 1200px;
    height: 420px;
    margin: -38px auto 0;
    overflow: visible;

    @media screen and (max-width: 1280px){
      max-width: 100%;
    }

    @media screen and (max-width: 600px){
      margin: -65px auto 0;
    }
`;

export const CarouselItem = styled.div`
  position: relative;
  height: 422px;

  img, picture {
    min-width: 1200px;
  }

  @media screen and (max-width: 830px){
    img, picture {
      min-width: 630px;
    }
  }

  .desk {
    @media screen and (max-width: 830px){
      display: none;
    }
  }

  .mobile {
    @media screen and (min-width: 831px){
      display: none;
    }
  }
`;

const UnderRingContainer = styled.div`
  position: absolute;
  top: -60px;
  right: -130px;
  z-index: 0; 
  min-width: 540px;
`;

const OverRingContainer = styled.div`
  position: absolute;
  top: -60px;
  right: -130px;
  z-index: 1; 
  min-width: 220px;
  max-width: 220px;
  overflow: hidden;
`;

const UnderRing = () => (
  <UnderRingContainer>
    <StaticImage src='../../images/Circulo-Dourado.png' width={540} placeholder="blurred"/>
  </UnderRingContainer>
);

const OverRing = () => (
  <OverRingContainer>
    <div style={{
      position: 'relative', top: 0, right: 320, width: 540,
    }}>
      <StaticImage src='../../images/Circulo-Dourado.png' width={540} placeholder="blurred"/>
    </div>
  </OverRingContainer>
);

const BannerCarousel = ({ children }) => (
    <Container>
        <UnderRing />
        <HomeCarousel>
          {children}
        </HomeCarousel>
        <OverRing />
    </Container>
);

export default BannerCarousel;
