import * as React from 'react';
import styledComponents from 'styled-components';
import HomeHero from '../components/homeHero';
import { Layout } from '../components/layout';
import Treatments from '../components/treatments';
import ClinicalBody from '../components/clinicalBody';

const TopContainer = styledComponents.div`
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 0;
`;

const meta = [
  <meta key='description' name='description' content={'Site da Clínica Dermatológica Angelucci, contendo informações sobre nossa clínica, tratamentos e dados de contato, além de nossa localização.'} />,
];

const IndexPage = () => (
  <Layout title={'Clínica Dermatológica Angelucci - Home'} meta={meta} >
    <main>
      <TopContainer>
        <HomeHero />
        <Treatments />
      </TopContainer>
      <ClinicalBody />
    </main>
  </Layout>
);

export default IndexPage;
