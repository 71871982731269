/* eslint-disable react/prop-types */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import THEME from '../../themes';
import BannerCarousel, { CarouselItem } from './Carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { SecondaryLinkButton } from '../button';

const Container = styled.div`
  position: relative;
  width: 100%;

  .noMobo {
    @media screen and (max-width: 562px){
      display: none;
    }
  }

  @media screen and (max-width: 750px){
    margin-bottom: 80px;
  }
`;

const TextContainer = styled.div`
    position: absolute;
    top: 30px;
    right: 50px;
    width: 95%;
    max-width: ${({ maxWidth }) => (maxWidth || '459px')};
    padding: 10px;
    margin: 0 auto;
    text-align: right;
    color: ${({ color }) => (color || THEME.COLORS.WHITE)};
    padding-left: 16px;
    padding-right: 16px;
    text-shadow: 2px 4px 10px rgba(0,0,0,0.6);
    font: normal normal normal 22px/32px ${THEME.TEXT.FONT_NAME};

    @media screen and (max-width: 831px){
      width: 95%;
      right: 13px;
      top: 30px;
    }

    @media screen and (max-width: 562px){
      width: 95%;
      right: 13px;
      top: 80px;
    }
`;

const BannerTitle = styled.p`
  font: normal normal normal 22px/65px ${THEME.TEXT.FONT_NAME};
  text-transform: uppercase;
  line-height: 1.8;

  strong {
    font: normal normal bold 60px/65px ${THEME.TEXT.FONT_NAME};
  }
  
  @media screen and (max-width: 831px){
    strong {
      font: normal normal bold 50px/65px PT Sans;
    }
  }
`;

const HomeHero = () => (
  <Container>
    <BannerCarousel>
      <CarouselItem>
          <StaticImage className='desk' src='../../images/banners/jpg/Banners-CDABH-AClinica-Mobile.jpg' placeholder="blurred" quality={100}/>
          <StaticImage className='mobile' src='../../images/banners/jpg/Banners-CDABH-AClinica-DeskTablet.jpg' placeholder="blurred" quality={100}/>
          <TextContainer maxWidth='400px'>
            <BannerTitle><br /><strong>A Clínica</strong></BannerTitle>
            <SecondaryLinkButton to='/a-clinica/'>Saiba mais</SecondaryLinkButton>
          </TextContainer>
        </CarouselItem>
        <CarouselItem>
          <StaticImage className='desk' src='../../images/banners/jpg/Banners-CDABH-Clinica-Mobile.jpg' placeholder="blurred" quality={100}/>
          <StaticImage className='mobile' src='../../images/banners/jpg/Banners-CDABH-Clinica-DeskTablet.jpg' placeholder="blurred" quality={100}/>
          <TextContainer maxWidth='400px'>
            <BannerTitle>dermatologia<br /><strong>clínica</strong></BannerTitle>
            <p className='noMobo'>
              Tem como objetivo diagnosticar, prevenir e tratar doenças
              que acometem a pele, cabelos e unhas.
            </p>
            <SecondaryLinkButton to='/tratamentos/dermatologia_clinica/'>Saiba mais</SecondaryLinkButton>
          </TextContainer>
        </CarouselItem>
        <CarouselItem>
          <StaticImage className='desk' src='../../images/banners/jpg/Banners-CDABH-Cirurgica-Mobile.jpg' placeholder="blurred" quality={100}/>
          <StaticImage className='mobile' src='../../images/banners/jpg/Banners-CDABH-Cirurgica-DeskTablet.jpg' placeholder="blurred" quality={100}/>
          <TextContainer>
            <BannerTitle>dermatologia<br /><strong>cirúrgica</strong></BannerTitle>
            <p className='noMobo'>
              A dermatologia em procedimentos cirúrgicos e estéticos para prevenir,
              restaurar e manter a saúde da pele, cabelo e unhas.
            </p>
            <SecondaryLinkButton to='/tratamentos/dermatologia_cirurgica/'>Saiba mais</SecondaryLinkButton>
          </TextContainer>
        </CarouselItem>
        <CarouselItem>
          <StaticImage className='desk' src='../../images/banners/jpg/Banners-CDABH-Estetica-Mobile.jpg' placeholder="blurred" quality={100}/>
          <StaticImage className='mobile' src='../../images/banners/jpg/Banners-CDABH-Estetica-DeskTablet.jpg' placeholder="blurred" quality={100}/>
          <TextContainer maxWidth='480px'>
            <BannerTitle>dermatologia<br /><strong>estética</strong></BannerTitle>
            <p className='noMobo'>
              A dermatologia nos tratamentos estéticos faciais, corporais e capilares
              no mais alto nível de excelência em saúde, beleza e bem-estar.
            </p>
            <SecondaryLinkButton to='/tratamentos/dermatologia_estetica/'>Saiba mais</SecondaryLinkButton>
          </TextContainer>
        </CarouselItem>
        <CarouselItem>
          <StaticImage className='desk' src='../../images/banners/jpg/Banners-CDABH-Capilar-Mobile.jpg' placeholder="blurred" quality={100}/>
          <StaticImage className='mobile' src='../../images/banners/jpg/Banners-CDABH-Capilar-DeskTablet.jpg' placeholder="blurred" quality={100}/>
          <TextContainer maxWidth='480px'>
            <BannerTitle>tratamentos<br /><strong>capilares</strong></BannerTitle>
            <p className='noMobo'>
              Alta tecnologia associada a diagnósticos e procedimentos capazes de prevenir,
              tratar e manter a saúde capilar com máxima eficiência.
            </p>
            <SecondaryLinkButton to='/tratamentos/tratamentos_capilares/'>Saiba mais</SecondaryLinkButton>
          </TextContainer>
        </CarouselItem>
    </BannerCarousel>
  </Container>
);

export default HomeHero;
